import { Alert, Button, Divider, Drawer, Segmented, Switch } from "antd";
import React from "react";
import { useGlobalConfig } from "@/providers/app/GlobalConfigProvider";
import useDisclosure from "@/hooks/shared/useDisclosure";
import { usePhoneFeatures } from "@/providers/app/PhoneFeaturesProvider";
import {
  AuthMethod,
  useAuthentication,
} from "@/providers/app/AuthenticationProvider";
import styled from "styled-components";
import { ENVIRONMENTS } from "@/providers/app/Environment/environments";
import { useEnvironment } from "@/providers/app/EnvironmentProvider";
import { useRouter } from "next/navigation";
import { useGlobalAppState } from "@/providers/app/GlobalAppStateProvider";
import { useLocalStorage } from "usehooks-ts";
import { LOCAL_STORAGE_KEYS } from "@/utils/app/constants";

const Styled = styled.div`
  background-color: transparent;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  .ant-alert-description {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-alert-warning {
    background-color: transparent;
  }
`;

const DevAlert = () => {
  const { callNumber } = usePhoneFeatures();
  const envs = useEnvironment();
  const { COMPANY_ID } = useGlobalAppState();
  const { push } = useRouter();
  const { checkingPermission, setCheckingPermission } = useGlobalConfig();
  const { isOpen, close, open } = useDisclosure(false);
  const { logout, login } = useAuthentication();
  const { setRuntimeEnvironment, currentEnvironment } = useEnvironment();
  const [enableDevMenu] = useLocalStorage<boolean>(
    LOCAL_STORAGE_KEYS.ENALBE_DEV_MENU,
    false,
    {
      deserializer: () => false,
    }
  );

  if (currentEnvironment === ENVIRONMENTS.PROD || !enableDevMenu) {
    return <></>;
  }

  return (
    <Styled>
      <Alert
        banner
        showIcon={false}
        style={{ padding: "2px 8px" }}
        description={
          <Button type="link" onClick={open}>
            Bấm để mở menu Dev
          </Button>
        }
      />
      <Drawer title="Dev Tools" onClose={close} open={isOpen}>
        <div className="d-flex flex-column px-2">
          <div className="my-2">
            <Segmented
              block
              disabled={process.env.NODE_ENV === "production"}
              value={currentEnvironment}
              options={Object.keys(ENVIRONMENTS)}
              onChange={(value) => {
                setRuntimeEnvironment(value);
              }}
            />
          </div>
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div className="ml-2">
              Kiểm tra quyền và chặn các tính năng tương ứng
            </div>
            <Switch
              checked={checkingPermission}
              onChange={(state) => setCheckingPermission(state)}
              style={{ marginLeft: "16px" }}
            />
          </div>
          <Divider />
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div>Tính năng gọi điện</div>
            <Button
              onClick={() => {
                push(`/dashboard/${COMPANY_ID}/debug/phone`);
              }}>
              Trang debug
            </Button>
          </div>
          <Divider />
          <div className="d-flex flex-column align-items-start gap-2">
            <div>Luồng Authentication</div>
            <Button
              onClick={() => {
                const email = window.prompt("Nhập email");
                const password = window.prompt("Nhập password");
                if (email && password) {
                  login({
                    method: AuthMethod.credentials,
                    parameters: {
                      username: email,
                      password: password,
                    },
                  });
                }
              }}>
              Đăng Nhập bằng Email Password
            </Button>
            <Button
              onClick={() => {
                const apiKey = window.prompt("Nhập API Key");
                if (apiKey) {
                  login({
                    method: AuthMethod.apiKey,
                    parameters: {
                      apiKey: apiKey,
                    },
                  });
                }
              }}>
              Đăng Nhập bằng API Key
            </Button>
            <Button
              onClick={() => {
                logout();
              }}>
              Đăng xuất
            </Button>
          </div>
          <textarea>{JSON.stringify(envs)}</textarea>
        </div>
      </Drawer>
    </Styled>
  );
};

export default DevAlert;
